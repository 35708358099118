/**
 * Funkčnosť pre 'Zobraziť viac' vozidiel.
 */
const initCarList = () => {
  const spinner = (color = "primary") =>
    `<div class="spinner-border text-${color}" role="status">
      <span class="sr-only">Načítavam...</span>
    </div>`

  const carList = document.querySelector(".car-list")

  const activateNextPageLink = () => {
    const nextPageLink = document.getElementById("next-page-link")

    nextPageLink &&
      nextPageLink.addEventListener("click", (evt) => {
        evt.preventDefault()

        nextPageLink.innerHTML = spinner("light")

        const href = nextPageLink.getAttribute("href")

        fetch(href, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-Component": "list",
          },
        })
          .then((response) => response.text())
          .then((body) => {
            nextPageLink.remove()
            carList.innerHTML += body
            activateNextPageLink()
            history.pushState("", "", href)
          })
      })
  }

  activateNextPageLink()
}

export default initCarList
