// Bootstrap
import "bootstrap/js/dist/util"
// import "bootstrap/js/dist/alert"
// import "bootstrap/js/dist/button"
import "bootstrap/js/dist/carousel"
import "bootstrap/js/dist/collapse"
// import "bootstrap/js/dist/dropdown"
// import "bootstrap/js/dist/index"
import "bootstrap/js/dist/modal"
// import "bootstrap/js/dist/popover"
// import "bootstrap/js/dist/scrollspy"
// import "bootstrap/js/dist/tab"
// import "bootstrap/js/dist/toast"
// import "bootstrap/js/dist/tooltip"

import "./katalogaut/scss/base.scss"

import "./katalogaut/js/carleader/panelmenu"
import "./katalogaut/js/carleader/main"

import "ekko-lightbox/dist/ekko-lightbox"
import "ekko-lightbox/dist/ekko-lightbox.css"

import "sumoselect/jquery.sumoselect.js"

import "./layouts/Box.css"
import "./layouts/Box"
import "./layouts/Center.css"
import "./layouts/Center"
import "./layouts/Cluster.css"
import "./layouts/Cluster"
import "./layouts/Imposter.css"
import "./layouts/Imposter"
import "./layouts/Sidebar.css"
import "./layouts/Sidebar"
import "./layouts/Stack.css"
import "./layouts/Stack"

import initCarList from "./katalogaut/cars/list"
import initHomeFilter from "./katalogaut/cars/home_filter"
import initListFilter from "./katalogaut/cars/list_filter"
import initAkciaModal from "./katalogaut/cars/akcia_modal"

const onReady = (fn) => {
  if (document.readyState !== "loading") {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

onReady(() => {
  initHomeFilter()
  initListFilter()
  initCarList()
  initAkciaModal()
})
