import noUiSlider from "nouislider"
import filterSumoSelectConfig from "./filter_sumoselect_config"

const btnActiveClass = "filter-form-group__btn--active"

const initListFilter = () => {
  const filterFormWrapper = document.getElementById("tt-filters-aside")

  if (filterFormWrapper !== null) {
    let {
      rokVyrobyMin,
      rokVyrobyMax,
      cenaMin,
      cenaMax,
      najazdeneKmMin,
      najazdeneKmMax,
      objemMotoraMin,
      objemMotoraMax,
      vykonMin,
      vykonMax,
    } = JSON.parse(document.getElementById("filter-data").textContent)

    /**
     * Funkčnosť filtra.
     */
    const activateFilter = () => {
      // zapnutie SumoSelect pre selecty
      ;[
        "id_typ_vozidla",
        "id_znacka",
        "id_status",
        "id_zastupenie",
        "id_druh_paliva",
        "id_farba",
        "id_poradie",
      ].map((elementId) => {
        $(`#${elementId}`).SumoSelect(filterSumoSelectConfig)
      })

      $("#id_model").SumoSelect({ ...filterSumoSelectConfig, placeholder: "Model" })

      const filterForm = filterFormWrapper.querySelector("form")

      // pri zmene značky sa znovu načíta formulár filtra
      const brandSelect = document.getElementById("id_znacka")

      $(brandSelect).on("change", () => {
        fetch(`?${new URLSearchParams([...new FormData(filterForm)])}`, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-Component": "filter_form",
          },
        })
          .then((response) => response.text())
          .then((body) => {
            filterFormWrapper.innerHTML = body
            activateFilter()
          })
      })

      // poradie
      const orderSelect = document.getElementById("id_poradie")

      // zmena poradia
      $(orderSelect).on("change", () => {
        const formData = new FormData(filterForm)
        formData.append("poradie", orderSelect.value)
        location.search = `?${new URLSearchParams([...formData])}`
      })

      // inicializácia sliderov
      const searchParams = new URLSearchParams(location.search)

      const slidersParams = [
        {
          baseFieldName: "rok_vyroby",
          from: rokVyrobyMin,
          to: rokVyrobyMax,
          step: 1,
          displayFormattingFunction: null,
          collapsible: true,
        },
        {
          baseFieldName: "cena",
          from: cenaMin,
          to: cenaMax,
          step: 1000,
          displayFormattingFunction: (value) =>
            value.toLocaleString("sk-SK", {
              maximumFractionDigits: 0,
            }),
          collapsible: false,
        },
        {
          baseFieldName: "najazdene_km",
          from: najazdeneKmMin,
          to: najazdeneKmMax,
          step: 1000,
          displayFormattingFunction: (value) =>
            value.toLocaleString("sk-SK", {
              maximumFractionDigits: 0,
            }),
          collapsible: false,
        },
        {
          baseFieldName: "objem_motora",
          from: objemMotoraMin,
          to: objemMotoraMax,
          displayFormattingFunction: (value) =>
            value.toLocaleString("sk-SK", {
              maximumFractionDigits: 0,
            }),
          collapsible: false,
        },
        {
          baseFieldName: "vykon",
          from: vykonMin,
          to: vykonMax,
          displayFormattingFunction: null,
          collapsible: false,
        },
      ]

      for (const {
        baseFieldName,
        from,
        to,
        step,
        displayFormattingFunction,
        collapsible,
      } of slidersParams) {
        const slider = noUiSlider.create(
          document.getElementById(`filter-${baseFieldName}-slider`),
          {
            start: [from, to],
            step: step,
            connect: true,
            range: {
              min: from,
              max: to,
            },
            format: {
              to: (value) => parseInt(value),
              from: (value) => parseInt(value),
            },
          }
        )

        // nastavenie pozícií podľa search parametrov v URL
        const searchFrom = searchParams.get(`${baseFieldName}_od`)

        if (searchFrom !== null) {
          slider.set([searchFrom, null])
        }

        const searchTo = searchParams.get(`${baseFieldName}_do`)

        if (searchTo !== null) {
          slider.set([null, searchTo])
        }

        // pre collapsible slidery sa zobrazí sa collapse,
        // keď je parameter prítomný v search params
        if (collapsible && searchFrom !== null) {
          $(`#filter-${baseFieldName}-collapse`).collapse({
            toggle: true,
          })
        }

        // form elementy do ktorých sa zapisujú hodnoty
        const formInputFrom = document.getElementById(`id_${baseFieldName}_od`)
        const formInputTo = document.getElementById(`id_${baseFieldName}_do`)

        // elementy na zobrazenie hodnôt
        const displayElFrom = document.getElementById(
          `filter-display-${baseFieldName}_od`
        )
        const displayElTo = document.getElementById(
          `filter-display-${baseFieldName}_do`
        )

        slider.on("update", (values, handle) => {
          const value = values[handle]

          if (handle === 0) {
            formInputFrom.value = value
            if (displayFormattingFunction !== null) {
              displayElFrom.innerText = displayFormattingFunction(value)
            } else {
              displayElFrom.innerText = value
            }
          }

          if (handle === 1) {
            formInputTo.value = value
            if (displayFormattingFunction !== null) {
              displayElTo.innerText = displayFormattingFunction(value)
            } else {
              displayElTo.innerText = value
            }
          }
        })
      }

      // funkčnosť polí formulára s tlačidlami
      filterForm
        .querySelectorAll(".js-filter-form-group-with-buttons")
        .forEach((group) => {
          const input = group.querySelector("input")
          const buttons = group.querySelectorAll("button")

          const initialValue = input.value

          buttons.forEach((button) => {
            if (button.dataset.value === initialValue) {
              button.classList.add(btnActiveClass)
            }

            button.addEventListener("click", () => {
              if (button.classList.contains(btnActiveClass)) {
                button.classList.remove(btnActiveClass)
                input.value = ""
              } else {
                buttons.forEach((button) => {
                  button.classList.remove(btnActiveClass)
                })
                button.classList.add(btnActiveClass)
                input.value = button.dataset.value
              }
            })
          })
        })

      // odoslanie formulára
      filterForm.addEventListener("submit", (evt) => {
        evt.preventDefault()
        const formData = new FormData(filterForm)

        // doplní sa poradie, ktorého input sa nachádza mimo form element
        formData.append("poradie", orderSelect.value)

        // keď sú slidery collapsed, tak sa ich hodnoty nepoužijú pri filtrácii
        for (const { baseFieldName, collapsible } of slidersParams) {
          if (collapsible) {
            const collapse = document.getElementById(`filter-${baseFieldName}-collapse`)
            if (!collapse.classList.contains("show")) {
              formData.delete(`${baseFieldName}_od`)
              formData.delete(`${baseFieldName}_do`)
            }
          }
        }

        location.search = `?${new URLSearchParams([...formData])}`
      })
    }

    activateFilter()
  }
}

export default initListFilter
