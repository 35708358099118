const initAkciaModal = () => {
  const modal = document.getElementById("akcia-modal")
  if (modal !== null) {
    $("#akcia-modal").on("show.bs.modal", function (event) {
      const button = $(event.relatedTarget)
      const akciaUrl = button.data("akcia-url")

      let modalBody = `<div class="modal-body">${button.data("akcia-popis")}`
      if (akciaUrl !== "") {
        modalBody += `
        <a
          class="primary-color font2 text-decoration:underline"
          href="${akciaUrl}"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zistiť viac
        </a>`
      }
      modalBody += "</div>"

      modal.querySelector(".modal-content").innerHTML = `<div class="modal-header">
        <h5 class="modal-title font2" id="akcia-modalLabel">${button.data("akcia")}</h5>
      </div>
      ${modalBody}
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Zavrieť
        </button>
        <a href="${button.data("car-url")}" class="btn btn-primary">Podrobnosti</button>
      </div>`
    })
  }
}

export default initAkciaModal
