import filterSumoSelectConfig from "./filter_sumoselect_config"

const initHomeFilter = () => {
  if (document.getElementById("home-filter") !== null) {
    const homeFilterFormWrapper = document.getElementById("home-filter-form-wrapper")
    const homeFilterSpinner = document.getElementById("home-filter-spinner")

    const showSpinner = () => homeFilterSpinner.classList.remove("d-none")

    /**
     * Načíta filter podľa aktuálnych parametrov zvolených vo formulári.
     */
    const reloadFilter = () => {
      showSpinner()

      const homeFilterForm = document.getElementById("home-filter")

      fetch(
        `${homeFilterForm.action}?${new URLSearchParams([
          ...new FormData(homeFilterForm),
        ])}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "X-Component": "home_filter_form",
          },
        }
      )
        .then((response) => response.text())
        .then((body) => {
          homeFilterFormWrapper.innerHTML = body.toString()
          activateFilter()
        })
    }

    /**
     * Aktivuje SumoSelect-y a dynamické zmeny filtra.
     */
    const activateFilter = () => {
      // zapnutie SumoSelect pre selecty
      ;["id_typ_vozidla", "id_znacka", "id_status", "id_zastupenie"].map(
        (elementId) => {
          $(`#${elementId}`).SumoSelect(filterSumoSelectConfig)
        }
      )

      $("#id_model").SumoSelect({ ...filterSumoSelectConfig, placeholder: "Model" })

      homeFilterSpinner.classList.add("d-none")

      const brandSelect = document.getElementById("id_znacka")

      // pri zmene značky sa znovu načíta filter kvôli zmene možností filtrácie modelu
      $(brandSelect).on("change", () => reloadFilter())
    }

    activateFilter()

    // ak sú pri načítaní stránky už nejaké vybrané značky, znovu sa načíta filter
    const brandSelectHasSelectedOption =
      [...document.getElementById("id_znacka").options].find((o) => o.selected) !==
      undefined

    if (brandSelectHasSelectedOption) {
      reloadFilter()
    }
  }
}

export default initHomeFilter
